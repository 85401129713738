import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom"; 
import "./RegistrationForm.css";
import axios from "axios";

function RegistrationForm ({  }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [speciality, setSpeciality] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

   // Fonction pour gérer la soumission du formulaire
  const handleRegister = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post('https://srv475095.hstgr.cloud/serein/register_doctor', {
        firstName,
        lastName,
        phoneNumber,
        address,
        speciality,
        email,
        password
      });

      console.log(response.data);

      // Traitez la réponse ici si nécessaire
      if (response.status === 200) {
        history.push('/LoginDoctor'); // Redirection vers la page de login
      }
    } catch (error) {
      console.error('Error:', error);

      if (error.response && error.response.data && error.response.data.detail) {
        console.log('Details de l\'erreur:', error.response.data.detail);
      }
    }
  };
      

  return (
      
    <div className="enregistrement-container">
        <form onSubmit={handleRegister} className="enregistrement-form">
            <h1>Register Doctor</h1>

            <div className="form-group">
                <label>
                    Nom du Docteur:
                <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                    required
                />
               </label>
            </div>

            <div className="form-group">
                <label>
                    Prenom du patient:
                <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                required
                />
                </label>
            </div>
            

            <div className="form-group">
                <label htmlFor="tel">
                    Numéro de téléphone:
                <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Phone Number"
                    required
                />
                </label>
            </div>


            <div className="form-group">
                <label>
                    Adresse:
                <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                />
                </label>
            </div>

            <div className="form-group">
                <label>
                    Spécialité:
                <input
                    type="text"
                    value={speciality}
                    onChange={(e) => setSpeciality(e.target.value)}
                    placeholder="Spécialité"
                    required
                />
                  </label>
            </div>

            <div className="form-group">
                <label htmlFor="email">
                    Adresse e-mail:
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                />
                </label>
            </div>

            <div className="form-group">
                <label>
                    Mot de Passe
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                </label>    
            </div>

            
        <p><Link to="/LoginDoctor">Back</Link></p>
        <button type="submit">Enregistrer</button>
        </form>
    </div>
  );
}

export default RegistrationForm;
