import React from "react";
import "./Urgence.css";

function Urgence(props) {
  const {
    numrosDurgence,
    ellipse5,
    number1,
    samu,
    number2,
    policeSecours,
    number3,
    pompiers,
    number4,
    numroDappelDurgenceEuropen,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="urgence screen">
        <div className="overlap-group1-5">
          <h1 className="numros-durgence-1 quicksand-bold-black-48px">{numrosDurgence}</h1>
          <a href="javascript:history.back()">
            <img className="ellipse-5-3" src={ellipse5} alt="Ellipse 5" />
          </a>
        </div>
        <div className="flex-row-8">
          <div className="overlap-group2-2">
            <div className="number quicksand-bold-white-40px">{number1}</div>
          </div>
          <div className="samu quicksand-medium-black-20px">{samu}</div>
        </div>
        <div className="flex-row-9">
          <div className="overlap-group4-2">
            <div className="number quicksand-bold-white-40px">{number2}</div>
          </div>
          <div className="police-secours quicksand-medium-black-20px">{policeSecours}</div>
        </div>
        <div className="flex-row-10">
          <div className="overlap-group-6">
            <div className="number quicksand-bold-white-40px">{number3}</div>
          </div>
          <div className="pompiers quicksand-medium-black-20px">{pompiers}</div>
        </div>
        <div className="flex-row-11">
          <div className="overlap-group3-2">
            <div className="number quicksand-bold-white-40px">{number4}</div>
          </div>
          <div className="numro-d-appel-d-urgence-europen quicksand-medium-black-20px">
            {numroDappelDurgenceEuropen}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Urgence;
