import React from "react";
import "./ChoixProfil2.css";

function ChoixProfil2(props) {
  const { title, identifiant, motDePasse, motDePasseOubli, seConnecter } = props;

  return (
    <div className="container-center-horizontal">
      <div className="choixu95profil screen">
        <h1 className="title quicksand-bold-black-48px">{title}</h1>
        <div className="identifiant quicksand-medium-black-20px">{identifiant}</div>
        <div className="rectangle-3-1"></div>
        <div className="mot-de-passe quicksand-medium-black-20px">{motDePasse}</div>
        <div className="rectangle-4"></div>
        <div className="mot-de-passe-oubli-1">{motDePasseOubli}</div>
        <div className="overlap-group-2">
          <div className="se-connecter quicksand-medium-black-20px">{seConnecter}</div>
        </div>
      </div>
    </div>
  );
}

export default ChoixProfil2;
