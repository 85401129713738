import React, { useState  } from "react";
import { Link, useHistory  } from "react-router-dom";
import "./EnregistrementPatientComponent.css";
import axios from "axios";

function EnregistrementPatientComponent({  }) {
  // États pour stocker les données du formulaire
  const [first_Name, setFirstName] = useState('');
  const [last_Name, setLastName] = useState('');
  const[date_of_birth,setDateOfBirth]=useState('')
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [social_security_number, setSocial_security_number] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  // Fonction pour gérer la soumission du formulaire
  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://srv475095.hstgr.cloud/serein/register', {
        first_Name,
        last_Name,
        date_of_birth,
        phoneNumber,
        address,
        social_security_number,
        email,
        password
      });

      console.log(response.data);

      if (response.status === 200) {
        history.push('/identification'); // Redirection vers la page de login
      }
    } catch (error) {
      console.error('Error:', error);

      if (error.response && error.response.data && error.response.data.detail) {
        console.log('Details de l\'erreur:', error.response.data.detail);
      }
    }
  };
      

    // Une fois l'enregistrement réussi, vous pouvez rediriger l'utilisateur ou effectuer d'autres actions

  return (
      
    <div className="enregistrement-container">
        <form onSubmit={handleRegister} className="enregistrement-form">
            <h1>Register</h1>

            <div className="form-group">
                <label>
                    Nom du patient:
                <input
                    type="text"
                    value={first_Name}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                    required
                />
               </label>
            </div>

            <div className="form-group">
                <label>
                    Prenom du patient:
                <input
                type="text"
                value={last_Name}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                required
                />
                </label>
            </div>
            
            <div className="form-group">
                <label htmlFor="date">
                    Date de naissance:
                <input
                    type="date"
                    value={date_of_birth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    required
                />
                </label>
            </div>

            <div className="form-group">
                <label htmlFor="tel">
                    Numéro de téléphone:
                <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Phone Number"
                    required
                />
                </label>
            </div>


            <div className="form-group">
                <label>
                    Adresse:
                <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                />
                </label>
            </div>

            <div className="form-group">
                <label>
                    Numéro de Sécurité Social:
                <input
                    type="text"
                    value={social_security_number}
                    onChange={(e) => setSocial_security_number(e.target.value)}
                    placeholder="Social Security Number"
                    required
                />
                </label>
            </div>

            <div className="form-group">
                <label htmlFor="email">
                    Adresse e-mail:
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                />
                </label>
            </div>

            <div className="form-group">
                <label>
                    Mot de Passe
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                </label>    
            </div>

            
        <p><Link to="/identification">Back</Link></p>
        <button type="submit">Enregistrer</button>
        </form>
    </div>
  );
}

export default EnregistrementPatientComponent;
