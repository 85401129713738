import React, { useState } from "react";
import "./Analyse1.css";
import axios from 'axios';

function Analyse1(props) {
  const { ellipse5, title, votrePossiumEstTr, oui1, votrePotassiumEst, oui2, oui3, non } = props;

  const [resultat, setResultat] = useState('');
  const [erreur, setErreur] = useState('');
  const [reponseMedicament, setReponseMedicament] = useState('');
  const [reponseAlimentsRiches, setReponseAlimentsRiches] = useState('');
  const [reponseAlimentsRiches2, setReponseAlimentsRiches2] = useState('');
  const [afficherPotassium, setAfficherPotassium] = useState(false);

  const afficherResultat = async () => {
    try {
      const response = await axios.get('https://srv475095.hstgr.cloud/serein/arbre-decision-creatinine');
      const currentDate = new Date().toLocaleDateString();
      const currentTime = new Date().toLocaleTimeString();
      const messageWithDateTime = `${currentDate} ${currentTime}: ${response.data.message}`;
      setResultat(messageWithDateTime);
    } catch (error) {
      console.error('Une erreur s\'est produite :', error);
    }
  };

  const handleVerifierPotassium = async () => {
    try {
      const response = await axios.get('https://srv475095.hstgr.cloud/serein/verifier-potassium');
      const currentDate = new Date().toLocaleDateString();
      const currentTime = new Date().toLocaleTimeString();
      const messageWithDateTime = `${currentDate} ${currentTime}: ${response.data.message}`;
      setResultat(messageWithDateTime);
    } catch (error) {
      console.error('Une erreur s\'est produite :', error);
    }
  };

  const handleOuiNonResponse = (question, reponse) => {
    if (question === "medicament") {
      setReponseMedicament(reponse);
    } else if (question === "alimentsRiches") {
      setReponseAlimentsRiches(reponse);
    } else if (question === "alimentsRiches2") {
      setReponseAlimentsRiches2(reponse);
    }
  
    
    if (reponseMedicament !== '' && reponseAlimentsRiches !== '' && reponseAlimentsRiches2 !== '') {
      if (reponseMedicament === 'oui') {
        if (reponseAlimentsRiches === 'oui') {
          setResultat('Votre potassium est trop élevé car vous avez consommé récemment des aliments riches en potassium. Veillez à bien suivre le régime conseillé par votre néphrologue et à poursuivre le traitement prescrit. Contactez rapidement votre médecin néphrologue pour l\'avertir des résultats.');
        } else if (reponseAlimentsRiches === 'non') {
          if (reponseAlimentsRiches2 === 'oui') {
            setResultat('Votre potassium est trop élevé car vous avez consommé récemment des aliments riches en potassium. Veillez à bien suivre le régime conseillé par votre néphrologue. Contactez rapidement votre médecin néphrologue pour l\'avertir des résultats.');
          } else if (reponseAlimentsRiches2 === 'non') {
            setResultat('Votre potassium est trop élevé malgré le régime suivi. Contactez rapidement votre médecin néphrologue pour l\'avertir des résultats et pour qu\'il adapte votre traitement.');
          }
        }
      } else if (reponseMedicament === 'non') {
        setResultat('Votre potassium est trop élevé car vous n\'avez pas pris le traitement permettant de le baisser. Veillez à bien prendre le médicament comme il vous a été prescrit. Contactez rapidement votre médecin néphrologue pour l\'avertir des résultats.');
      }
    }
  };
  

  const handlePotassiumClick = () => {
    setAfficherPotassium(true);
    handleVerifierPotassium();
  };

  return (
    <div className="container-center-horizontal">
      <div className="analyse1 screen">
        <div className="rectangle-7-2">
          <button onClick={afficherResultat}>Creatinine</button>
          <button onClick={handlePotassiumClick}>Potassium</button>
        </div>
        <a href="javascript:history.back()">
          <img className="ellipse-5-1" src={ellipse5} alt="Ellipse 5" />
        </a>
        <h1 className="title-4 quicksand-bold-black-48px">{title}</h1>
        <div className="overlap-group1-3">
          <p className="votre-possium-est-tr quicksand-medium-black-16px">{votrePossiumEstTr}</p>
        </div>
        {resultat && <div className="overlap-group2-1">{resultat}</div>}
        {erreur && <div className="erreur-message">{erreur}</div>}
        {afficherPotassium && !resultat && (
          <>
            <div>
              <p>Avez-vous bien pris le médicament KAYEXALATE prescrit par le médecin ?</p>
              <button onClick={() => handleOuiNonResponse("medicament", "oui")}>Oui</button>
              <button onClick={() => handleOuiNonResponse("medicament", "non")}>Non</button>
            </div>
            {reponseMedicament === 'oui' && (
              <div>
                <p>Avez-vous consommé dans les jours précédents des aliments riches en potassium ?</p>
                <button onClick={() => handleOuiNonResponse("alimentsRiches", "oui")}>Oui</button>
                <button onClick={() => handleOuiNonResponse("alimentsRiches", "non")}>Non</button>
              </div>
            )}
            {reponseMedicament === 'oui' && reponseAlimentsRiches === 'non' && reponseAlimentsRiches2 === '' && (
              <div>
                <p>Avez-vous consommé dans les jours précédents des aliments riches en potassium ?</p>
                <button onClick={() => handleOuiNonResponse("alimentsRiches2", "oui")}>Oui</button>
                <button onClick={() => handleOuiNonResponse("alimentsRiches2", "non")}>Non</button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Analyse1;
