
import "./ProfilUser.css";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

function ProfilUser(props) {
  const [userData, setUserData] = useState(null);
  const [doctorList, setDoctorList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const { ellipse3, title, franoisDupont, date, phone, fDupontGmailCom, modifier, changerMotDePasse } = props;
  const history = useHistory();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('https://srv475095.hstgr.cloud/serein/user-info', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.data) {
          const parsedData = JSON.parse(response.data);
          setUserData(parsedData);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur:', error);
      }
    };

    const fetchDoctorList = async () => {
      try {
        const response = await axios.get('https://srv475095.hstgr.cloud/serein/doctors', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.data && response.data.doctors) {
          setDoctorList(response.data.doctors);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération de la liste des médecins:', error);
      }
    };

    fetchUserData();
    fetchDoctorList();
  }, []);

  if (!userData) {
    return null;
  }

  const handleDoctorSelect = (e) => {
    const selectedDoctorId = e.target.value;
    console.log('Selected Doctor ID:', selectedDoctorId);
    setSelectedDoctor(selectedDoctorId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDoctor) {
      console.error('Aucun médecin sélectionné.');
      return;
    }

    console.log('Doctor ID to be sent:', selectedDoctor); // Vérification

    try {
      const response = await axios.post(`https://srv475095.hstgr.cloud/serein/choose_doctor/${selectedDoctor}`, null, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.data && response.data.message) {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error('Erreur lors du choix du médecin référent:', error);
    }
  };

  

  const handleLogout = async () => {
    try {
      const response = await fetch('https://srv475095.hstgr.cloud/serein/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (response.ok) {
        localStorage.removeItem('token');
        history.push('/identification');
      } else {
        const errorData = await response.json();
        console.error('Erreur lors de la déconnexion:', errorData.detail);
      }
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };


  return (
    <div className="container-center-horizontal">
      <div className="profil-user screen">

      

        <div className="flex-row-2">
          <a href="javascript:history.back()">
            <img className="ellipse-3" src={ellipse3} alt="Ellipse 3" />
          </a>
          <h1 className="title-1 quicksand-bold-black-48px">{title}</h1>
        </div>

        <div className="flex-row-7">
      <label htmlFor="doctorSelect">Choisissez votre médecin référent :</label>
      <select id="doctorSelect" value={selectedDoctor} onChange={handleDoctorSelect}>
  <option value="">Sélectionnez un médecin</option>
  {doctorList.map(doctor => (
    <option key={doctor.id} value={doctor.id}>{doctor.first_name} {doctor.last_name} ({doctor.speciality})</option>
  ))}
</select>

    </div>
    <button onClick={handleSubmit}>Choisir Néphrologue </button>
        
        

        <div className="flex-row-3">
          <img className="icon-user" src="/img/profil-2-1-2.svg" alt="icon-user" />
          <div className="franois-dupont quicksand-medium-black-20px">{userData.first_name} {userData.last_name}</div>
        </div>
        <div className="flex-row-4">
          <img className="icon-calendar" src="/img/calendrier-1-2.svg" alt="icon-calendar" />
          <div className="date quicksand-medium-black-20px"> {userData.date_of_birth}</div>
        </div>
        <div className="flex-row-5">
          <img className="tel-1" src="/img/tel-1-2.svg" alt="tel 1" />
          <p className="phone quicksand-medium-black-20px">{userData.phoneNumber.toString()}</p>
        </div>
        <div className="mail-container">
          <img className="icon-mail" src="/img/mail-1-2.svg" alt="icon-mail" />
          <a href="mailto:f.dupont@gmail.com" target="_blank">
            <div className="fdupontgmailcom quicksand-medium-black-20px">{userData.email}</div>
          </a>
        </div>
        <div className="flex-row-6">
          <img className="icon-home" src="/img/maison-1-2.svg" alt="icon-home" />
          <p className="address quicksand-medium-black-20px">{userData.address}</p>
        </div>


        <div className="overlap-group-3">
          <div className="modifier quicksand-medium-black-20px">{modifier}</div>
        </div>

        {/* <div className="overlap-group-3">
          <div className="modifier quicksand-medium-black-20px">Edit MDP</div>
        </div> */}

        <div className="logout-button">
          <button onClick={handleLogout}>Déconnexion</button>
        </div>
       

      </div>
    </div>
    );
  
}

export default ProfilUser;


