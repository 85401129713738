import React from "react";
import ReactDOM from "react-dom/client";
import "./globals.css"
import "./styleguide.css"
import App from "./App";
//import jwt

//ReactDOM.render(<App />, document.getElementById("app"));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);


//Clé secrète pour signer les jetons
//SECRET_KEY = "votre_clé_secrète"

//Fonction pour créer un jeton JWT
//def create_token(user_id: str) -> str:
    //token = jwt.encode({"user_id": user_id}, SECRET_KEY, algorithm="HS256")
    //return token

