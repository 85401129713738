import React from "react";
import { Link, useHistory  } from "react-router-dom";
import "./ChoixProfil4.css";

function ChoixProfil4(props) {
  const { place, jeSuisPatient, jeSuisMdecin,handleUserChoice  } = props;
  const history = useHistory();

  const handleChoice = (choice) => {
    // Rediriger vers la page d'identification
    history.push("/identification");
    // history.push("/urgence");

  };


  return (
    <div className="container-center-horizontal">
      <div className="choix-profil screen">
        <h1 className="place-4 quicksand-bold-black-48px">{place}</h1>

        <div className="overlap-group1-14">
          <Link to="/identification">
            <div className="rectangle-2-1"></div>
          </Link>

          <div
            className="je-suis-patient-1 quicksand-medium-black-32px"
            onClick={() => handleChoice("patient")}
          >
            {jeSuisPatient}
          </div>
        </div>

        <div className="overlap-group-16">
          <Link to="/LoginDoctor">
            <div className="rectangle-3-3"></div>
          </Link>

          <div
            className="je-suis-mdecin-1 quicksand-medium-black-32px"
            onClick={() => handleChoice("medecin")}
          >
            {jeSuisMdecin}
          </div>
        </div>
      </div>
    </div>
  );
}



//   return (
//     <div className="container-center-horizontal">
//       <div className="choix-profil screen">
//       <div>
//       <h1>Bienvenue sur l'accueil</h1>
//       <Link to="/identification">Page d'identification</Link>
//     </div>
//         <h1 className="place-4 quicksand-bold-black-48px">{place}</h1>
//         <div className="overlap-group1-14">
//           <Link to="/accueil">
//             <div className="rectangle-2-1"></div>
//           </Link>
//           <div className="je-suis-patient-1 quicksand-medium-black-32px" onClick={() => handleChoice("patient")}>{jeSuisPatient}</div>
//         </div>
//         <div className="overlap-group-16">
//           <Link to="/inter-medecin1">
//             <div className="rectangle-3-3"></div>
//           </Link>
//           <div className="je-suis-mdecin-1 quicksand-medium-black-32px" onClick={() => handleChoice("medecin")}>{jeSuisMdecin}</div>
//         </div>
//       </div>
//     </div>
//   );
// }

export default ChoixProfil4;

// ...
// function ChoixProfil4({ place, jeSuisPatient, jeSuisMdecin, onUserChoice }) {
//   const handleChoice = (choice) => {
//     onUserChoice(choice); // Appeler la fonction fournie par App avec le choix de l'utilisateur
//   };

//   return (
//     <div>
//       <h2>Choisissez votre profil</h2>
//       <button onClick={() => handleChoice('patient')}>{jeSuisPatient}</button>
//       <button onClick={() => handleChoice('medecin')}>{jeSuisMdecin}</button>
//     </div>
//   );
// }

// export default ChoixProfil4;
