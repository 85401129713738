import React from "react";
import "./ModifierProfil.css";

function ModifierProfil(props) {
  const { ellipse3, title, franoisDupont, date, phone, fDupontGmailCom, address, enregistrer } = props;

  return (
    <div className="container-center-horizontal">
      <div className="modifier-profil screen">
        <div className="flex-row-22">
          <div className="flex-col-3">
            <a href="javascript:history.back()" className="align-self-flex-start">
              <img className="ellipse-3-2" src={ellipse3} alt="Ellipse 3" />
            </a>
            <img className="icon-user-2" src="/img/profil-2-1-1.svg" alt="icon-user" />
            <img className="icon" src="/img/calendrier-1-1.svg" alt="icon-calendar" />
            <img className="tel-1-2" src="/img/tel-1-1.svg" alt="tel 1" />
            <img className="icon" src="/img/mail-1-1.svg" alt="icon-mail" />
            <img className="icon" src="/img/maison-1-1.svg" alt="icon-home" />
          </div>
          <div className="flex-col-4">
            <h1 className="title-8 quicksand-bold-black-48px">{title}</h1>
            <div className="overlap-group1-11">
              <div className="franois-dupont-2 quicksand-medium-black-20px">{franoisDupont}</div>
            </div>
            <div className="overlap-group2-5">
              <div className="date-1 quicksand-medium-black-20px">{date}</div>
            </div>
            <div className="overlap-group4-5">
              <p className="phone-1 quicksand-medium-black-20px">{phone}</p>
            </div>
            <div className="overlap-group3-5">
              <a href="mailto:f.dupont@gmail.com" target="_blank">
                <div className="fdupontgmailcom-1 quicksand-medium-black-20px">{fDupontGmailCom}</div>
              </a>
            </div>
            <div className="overlap-group-13">
              <p className="address-5 quicksand-medium-black-20px">{address}</p>
            </div>
          </div>
        </div>
        <div className="overlap-group5-1">
          <div className="enregistrer quicksand-medium-black-20px">{enregistrer}</div>
        </div>
      </div>
    </div>
  );
}

export default ModifierProfil;
