import React from "react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ListeTraitement2.css";

function ListeTraitement2(props) {
  const {
    title,
    ellipse5,
    kaleorid,
    address1,
    aranesp,
    address2,
    cacit,
    address3,
    mimpara,
    address4,
    historique,
    ellipse11,
  } = props;

  const [dernierTraitement, setDernierTraitement] = useState([]);

  useEffect(() => {
    async function fetchDernierTraitement() {
      const response = await fetch("https://srv475095.hstgr.cloud/serein/dernier-traitement");
      
      const data = await response.json();
      setDernierTraitement(data);
    }
  
    fetchDernierTraitement();
  }, []);
  


  return (
    <div className="container-center-horizontal">
      <div className="liste-traitement2 screen">
      <div className="overlap-group1-4">
        <h1 className="title-5 quicksand-bold-black-48px">{title}</h1>
        <a href="javascript:history.back()">
          <img className="ellipse-5-2" src={ellipse5} alt="Ellipse 5" />
        </a>
      </div>
        <div className="liste-traitement2-item quicksand-medium-black-20px">
          {dernierTraitement.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
        <div className="overlap-group-5">
        <div className="historique quicksand-medium-black-16px">{historique}</div>
        <Link to="/histo-traitement">
          <img className="ellipse-11-1" src={ellipse11} alt="Ellipse 11" />
        </Link>
      </div>
      </div>
    </div>
  );
  
}

export default ListeTraitement2;