import React from "react";
import "./Analyse2.css";

function Analyse2(props) {
  const { ellipse5, title, votrePossiumEstTr, oui, votrePotassiumEst } = props;

  return (
    <div className="container-center-horizontal">
      <div className="analyse2 screen">
        <div className="rectangle-7-4"></div>
        <a href="javascript:history.back()">
          <img className="ellipse-5-6" src={ellipse5} alt="Ellipse 5" />
        </a>
        <h1 className="title-9 quicksand-bold-black-48px">{title}</h1>
        <div className="overlap-group-15">
          <p className="votre-possium-est-tr-1 quicksand-medium-black-16px">{votrePossiumEstTr}</p>
        </div>
        <div className="overlap-group2-7">
          <div className="oui-1 quicksand-medium-black-16px">{oui}</div>
        </div>
        <div className="overlap-group1-13">
          <p className="votre-potassium-est-1 quicksand-medium-black-16px">{votrePotassiumEst}</p>
        </div>
      </div>
    </div>
  );
}

export default Analyse2;
