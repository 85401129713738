import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './LoginDoc.css';


function LoginDoc() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [photo, setPhoto] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://srv475095.hstgr.cloud/serein/login_doctor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, photo }),
        credentials: 'include', // Ajoutez cette ligne

      });

      if (response.ok) {
        const data = await response.json();
        //storing a user_id
        //sessionStorage.setItem("user_id", user_id);
        const doctorId = data.doctor_id;
        localStorage.setItem('token', data.token); // Stockez le token dans localStorage
        history.push(`/inter-medecin1/${doctorId}`);
      } else {
        throw new Error('Identifiants incorrects');
      }
    } catch (error) {
      setError('Identifiants incorrects');
      console.error('Erreur :', error);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPhoto(reader.result);
    };
  };

  useEffect(() => {
    // using the user_id
    // const user_id = sessionStorage.getItem("user_id");
    const token = localStorage.getItem('token');
    if (token) {
      history.push('/inter-medecin1');
    }
  }, []);

  return (
    <div className="identification-container">
      <form onSubmit={handleSubmit} className="identification-form">
        <h1>Identification du Docteur</h1>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Mot de passe</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <p>Vous n'avez pas de compte ? <Link to="/DoctorRegister">Inscrivez-vous ici</Link></p>
        <button type="submit">Se connecter</button>
      </form>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default LoginDoc;