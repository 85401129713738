import React, {useEffect, useState} from "react";
import { Link, useParams } from "react-router-dom";
import "./InfoPerso.css";
import axios from "axios";

function InfoPerso(props) {
  const [userData, setUserData] = useState(null);
  const { id } = useParams(); // Récupère l'ID du patient depuis les paramètres de l'URL
  const { informationsPersonnelles, ellipse3, nomDuPatient, dateDeNaissance, nDeTlphone, adresseMail, adresse } = props;



  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://srv475095.hstgr.cloud/serein/patients/${id}`);
        if (response.data && response.data.patient) {
          setUserData(response.data.patient);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations du patient:', error);
      }
    };

    fetchUserData();
  }, [id]);

  if (!userData) {
    return <div>Loading...</div>; // Vous pouvez afficher un message de chargement ici
  }
  

  return (
    <div className="container-center-horizontal">
      <div className="info-perso screen">
        <div className="overlap-group-12">
          <h1 className="informations-personnelles-1 quicksand-bold-black-48px">{informationsPersonnelles}</h1>
          <a href="javascript:history.back()">
            <img className="ellipse-3-1" src={ellipse3} alt="Ellipse 3" />
          </a>
        </div>
        <div className="flex-row-18">
          <img className="icon-user-1" src="/img/profil-2-1.svg" alt="icon-user" />
          <div className="nom-du-patient-1 quicksand-medium-black-20px">{userData.first_name} {userData.last_name}</div>
        </div>
        <div className="flex-row-19">
          <img className="icon-calendar-1" src="/img/calendrier-1.svg" alt="icon-calendar" />
          <div className="date-de-naissance quicksand-medium-black-20px">{userData.date_of_birth}</div>
        </div>
        <div className="flex-row-20">
          <img className="tel-1-1" src="/img/tel-1.svg" alt="tel 1" />
          <div className="n-de-tlphone quicksand-medium-black-20px">{userData.phoneNumber}</div>
        </div>
        <div className="mail-container-1">
          <img className="icon-mail-1" src="/img/mail-1-2.svg" alt="icon-mail" />
          <div className="adresse-mail-1 quicksand-medium-black-20px">{userData.email}</div>
        </div>
        <div className="flex-row-21">
          <img className="icon-home-1" src="/img/maison-1.svg" alt="icon-home" />
          <div className="adresse quicksand-medium-black-20px">{userData.address}</div>
        </div>
      </div>
    </div>
  );
}

export default InfoPerso;
