import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "./InterMedecin1.css";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';

function InterMedecin1(props) {



  const { id } = useParams();
  const [uniquementMesPatientsChecked, setUniquementMesPatientsChecked] = useState(false);
  const [patients, setPatients] = useState([]);
  const {
    ellipse1,
    place,
    uniquementMesPatients,
    iconNotifications1
  } = props;

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await axios.get('https://srv475095.hstgr.cloud/serein/patients');
        if (response.data && response.data.patients) {
          if (uniquementMesPatientsChecked) {
            // Filtrer les patients pour ceux qui ont le médecin actuel
            const filteredPatients = response.data.patients.filter(patient => patient.doctor_id === id);
            setPatients(filteredPatients);
          } else {
            setPatients(response.data.patients);
          }
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des patients:', error);
      }
    };

    fetchPatients();
  }, [uniquementMesPatientsChecked]);

  const handleUniquementMesPatientsChange = () => {
    setUniquementMesPatientsChecked(!uniquementMesPatientsChecked);
  };

  return (
    <div className="container-center-horizontal">
    <div className="d-flex justify-content-around"></div>
      <div className="inter-medecin1 screen">
        <div className="rectangle-7-3"></div>
        <ButtonGroup aria-label="Basic example">
      <Button variant="secondary">Left</Button>
      <Button variant="secondary">Middle</Button>
      <Button variant="secondary">Right</Button>
    </ButtonGroup>
      <Card style={{ width: '18rem' }}>
        <Card.Img variant="top" src="holder.js/100px180" />
        <Card.Body>
          <Card.Title>Card Title</Card.Title>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
          <Button variant="primary">Go somewhere</Button>
        </Card.Body>
      </Card>

        <Link to="/Profil-Doctor">
          <img className="ellipse-1-1" src={ellipse1} alt="Ellipse 1" />
        </Link>
        <h1 className="place-1 quicksand-bold-black-48px">{place}</h1>
        <div className="rectangle-20"></div>
        <Button variant="outline-secondary" size="sm">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-search"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
            />
            <path
              fillRule="evenodd"
              d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
            />
          </svg>
        </Button>
        <h1>Liste des patients : </h1>
        {patients.map(patient => (
          <div  className="overlap-group6">
            <Link key={patient.id} to={`/compte-patient/${patient.id}`}>
              <div className="rectangle"></div>
            </Link>
            <div className="franois-dupont-1 quicksand-medium-black-20px">{patient.first_name} {patient.last_name}</div>
            <img className="icon-notifications-1" src={iconNotifications1} alt="icon-notifications" />
          </div>
        ))}
      </div>
    </div>
    );
  }
  
  export default InterMedecin1;
  
  