import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import './IdentificationPage.css';

function IdentificationPage() {
  const { id } = useParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`https://srv475095.hstgr.cloud/serein/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        // Storing a user_id
        // sessionStorage.setItem("user_id", user_id);
        const userId = data.user_id;
        localStorage.setItem('token', data.token); // Store the token in localStorage
        console.log(userId);
        history.push(`/accueil/${userId}`);
      } else {
        throw new Error('Invalid credentials');
      }
    } catch (error) {
      setError('Invalid credentials');
      console.error('Error:', error);
    }
  };

  return (
    <div className="identification-container">
      <form onSubmit={handleSubmit} className="identification-form">
        <h1>Patient Identification</h1>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <p>Don't have an account? <Link to="/registration">Sign up here</Link></p>
        <button type="submit">Log in</button>
      </form>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default IdentificationPage;
