import React, { useState, useRef } from 'react';
import './Camera.css';

  const Camera = () => {
  const videoRef = useRef(null);
  const [mediaStream,  setMediaStream] = useState(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      setMediaStream(stream);
    } catch (error) {
      console.error('Error accessing the camera:', error);
    }
  }; 

  const takePhoto = async () => {
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    try {
      // Convert the canvas content to a Blob
      const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));

      // Prompt user to save the Blob
      const anchor = document.createElement('a');
      anchor.download = 'photo.png';
      anchor.href = URL.createObjectURL(blob);
      anchor.click();
    } catch (error) {
      console.error('Error saving photo:', error);
    }
  };

  return (
    <div className="camera-container">
      <video ref={videoRef} autoPlay muted></video>
      <button onClick={startCamera}>Start Camera</button>
      <button onClick={takePhoto}>Take Photo</button>
    </div>
  );
};

export default Camera;