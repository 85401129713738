import React from "react";
import { Link, useHistory, useParams  } from "react-router-dom";
import Rectangle1 from "../Rectangle1";
import "./Accueil.css";
import {useLocation, useNavigate} from 'react-router-dom';

function Accueil(props) {
  const { id } = useParams();
  const location=useLocation()
  const {
    iconNotifications,
    place,
    ellipse1,
    Telechargements,
    appareilPhoto,
    analyses,
    traitements,
    fichiers,
    evolutions,
    numrosDurgence,
  } = props;

  const history = useHistory();

  const handleClick = () => {
    // Naviguer vers une autre page
    // history.push('/Fichiers');
  }


  
  return (
    <div className="container-center-horizontal">
      <div className="accueil screen">
        <div className="flex-row">
          <img className="icon-notifications" src="/img/notification.png" alt="icon-notifications" />
          <h1 className="place quicksand-bold-black-48px">{place}</h1>
          <Link to="/profil-user">
            <img className="ellipse-1" src="/img/profil-user.png" alt="Ellipse 1" />
          </Link>
        </div>
        <div className="flex-row-1 quicksand-medium-black-20px">
          <div className="flex-col">
            <Rectangle1 />
            <p className="appareil-photo">Telechargement</p>
            <div className="overl">
              {/* Utiliser la même classe CSS que les autres boutons */}
              <Link to="/camera" onClick={() => handleClick('/camera')}>
                <button className="overlap-group4" onClick={() => handleClick('/camera')}>
                  {/* Utiliser les mêmes styles que les autres boutons */}
                  <img className="camera-icon" src="/img/camera.png" alt="camera" />
                </button>
              </Link>
              <div className="appareil-photo">{appareilPhoto}</div>
            </div>
            <Link to="/analyse1" onClick={() => handleClick('/analyse1')}>
              <button className="overlap-group2" onClick={() => handleClick('/analyse1')}>
                <img className="icon-search" src="/img/analyse-1.PNG" alt="icon-search" />
              </button>
            </Link>
            <div className="analyses">{analyses}</div>
            <div className="overlap-g">
              <Link to="/liste-traitement2" onClick={() => handleClick('/liste-traitement2')}>
                <button className="overlap-group3" onClick={() => handleClick('/liste-traitement2')}>
                  <img className="traitement-1" src="/img/traitement-1.png" alt="traitement" />
                </button>
              </Link>
            </div>
            <div className="traitements">{traitements}</div>
          </div>
          <div className="flex-col-1">
            <div className="overlap-gro">
              <Link to={`/fichiers/${id}`} onClick={() => handleClick(`/fichiers/${id}`)}>
                <button id="traitement-1" className="overlap-group-1" onClick={() => handleClick(`/fichiers/${id}`)}>
                  <img className="photo3-1" src="/img/fichiers.png" alt="photo3 1" />
                </button>
              </Link>
            </div>
            <div className="fichiers">{fichiers}</div>
            <div className="overl">
              <Link to="/evolution" onClick={() => handleClick('/evolution')}>
                <button id="fichiers" className="overlap-group1-1" onClick={() => handleClick('/evolution')}>
                  <img className="evolution-1" src="/img/evolution-1.png" alt="evolution 1" />
                </button>
              </Link>
            </div>
            <div className="evolutions">{evolutions}</div>
            <div className="overl">
              <Link to="/urgence" onClick={() => handleClick('/urgence')}>
                <button id="urgence" className="overlap-group4" onClick={() => handleClick('/urgence')}>
                  <img className="tel-2" src="/img/tel-2.png" alt="tel 2" />
                </button>
              </Link>
            </div>
            <div className="numros-durgence">{numrosDurgence}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accueil;
