import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "./ComptePatient.css";

function ComptePatient(props) {
  const [patient, setPatient] = useState(null);
  const { id } = useParams(); // Récupère l'ID du patient depuis les paramètres de l'URL
  const {
    ellipse11,
    infoPerso1,
    informationsPersonnelles,
    fichiers,
    analyses,
    traitements,
    evolutions,
  } = props;

  
  useEffect(() => {
    const fetchPatientInfo = async () => {
      try {
        const response = await axios.get(`https://srv475095.hstgr.cloud/serein/patients/${id}`);
        if (response.data && response.data.patient) {
          setPatient(response.data.patient);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations du patient:', error);
      }
    };

    fetchPatientInfo();
  }, [id]);

  if (!patient) {
    return <div>Loading...</div>; // Vous pouvez afficher un message de chargement ici
  }


  return (
    <div className="container-center-horizontal">
      <div className="compte-patient quicksand-medium-black-20px screen">
        <div className="flex-row-16">
          <a href="javascript:history.back()">
            <img className="ellipse-11-3" src={ellipse11} alt="Ellipse 11" />
          </a>
          <h1 className="nom-du-patient quicksand-bold-black-48px">{patient.first_name}</h1>
        </div>
        <div className="overlap-group2-3">
          <Link to={`/info-perso/${patient.id}`}>
            <div className="rectangle-6"></div>
          </Link>
          <img className="info-perso-1" src={infoPerso1} alt="info perso 1" />
        </div>
        <div className="informations-personnelles">{informationsPersonnelles}</div>
        <div className="flex-row-17">
          <div className="flex-col-2">
            <div className="overlap-group-9">
            <Link to={`/fichiers/${patient.id}`}>
              <img className="photo3-1-1" src="/img/photo3-1-1.svg" alt="photo3 1" />
            </Link>
            </div>
            <div className="fichiers-2">{fichiers}</div>
            <div className="overlap-group3-3">
              <img className="icon-search-1" src="/img/analyse-1-1.svg" alt="icon-search" />
            </div>
            <div className="analyses-1">{analyses}</div>
          </div>
          <div className="flex-col-2">
            <div className="overlap-group1-8">
            <Link to={`/histo-traitement`}>
              <img className="traitement-1-1" src="/img/traitement-1-1.svg" alt="traitement 1" />
            </Link>
            </div>
            <div className="traitements-1">{traitements}</div>

            <div className="overlap-group4-3">
            <Link to={`/evolution`}>
              <img className="evolution-1-1" src="/img/evolution-1-1.svg" alt="evolution 1" />
            </Link>
            </div>
            <div className="evolutions-1">{evolutions}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComptePatient;
