import React from "react";
import { Link } from "react-router-dom";
import "./InterMedecin2.css";

function InterMedecin2(props) {
  const {
    ellipse1,
    place,
    rechercher,
    uniquementMesPatients,
    franoisDupont,
    iconNotifications1,
    name1,
    iconNotifications2,
    name2,
    iconNotifications3,
    valrieMoreau,
    iconNotifications4,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="inter-medecin2 screen">
        <div className="rectangle-24"></div>
        <div className="rectangle-24-1"></div>
        <Link to="/profil-user">
          <img className="ellipse-1-2" src={ellipse1} alt="Ellipse 1" />
        </Link>
        <h1 className="place-3 quicksand-bold-black-48px">{place}</h1>
        <div className="rectangle-20-1"></div>
        <img className="icon-search-3" src="/img/loupe-1-1.svg" alt="icon-search" />
        <div className="rechercher-1 quicksand-medium-black-20px">{rechercher}</div>
        <div className="uniquement-mes-patients-1 quicksand-medium-black-16px">{uniquementMesPatients}</div>
        <a href="javascript:history.back()">
          <div className="rectangle-24-2"></div>
        </a>
        <img className="line-3" src="/img/line-3.svg" alt="Line 3" />
        <img className="line-2" src="/img/line-2.svg" alt="Line 2" />
        <div className="overlap-group1-12">
          <Link to="/compte-patient">
            <div className="rectangle-1-1"></div>
          </Link>
          <div className="franois-dupont-3 quicksand-medium-black-20px">{franoisDupont}</div>
          <img className="icon-notifications-4" src={iconNotifications1} alt="icon-notifications" />
        </div>
        <div className="overlap-group-14">
          <Link to="/compte-patient">
            <div className="rectangle-1-1"></div>
          </Link>
          <div className="name-5 quicksand-medium-black-20px">{name1}</div>
          <img className="icon-notifications-4" src={iconNotifications2} alt="icon-notifications" />
        </div>
        <div className="overlap-group2-6">
          <Link to="/compte-patient">
            <div className="rectangle-1-1"></div>
          </Link>
          <div className="name-6 quicksand-medium-black-20px">{name2}</div>
          <img className="icon-notifications-4" src={iconNotifications3} alt="icon-notifications" />
        </div>
        <div className="overlap-group3-6">
          <Link to="/compte-patient">
            <div className="rectangle-1-1"></div>
          </Link>
          <div className="valrie-moreau-1 quicksand-medium-black-20px">{valrieMoreau}</div>
          <img className="icon-notifications-5" src={iconNotifications4} alt="icon-notifications" />
        </div>
      </div>
    </div>
  );
}

export default InterMedecin2;
