import React from "react";
import { Link } from "react-router-dom";
import "./ListeTraitement1.css";

function ListeTraitement1(props) {
  const {
    title,
    ellipse5,
    kaleorid,
    iconTrash1,
    address1,
    aranesp,
    iconTrash2,
    address2,
    cacit,
    iconTrash3,
    address3,
    mimpara,
    iconTrash4,
    address4,
    historique,
    ellipse11,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="liste-traitement1 screen">
        <div className="overlap-group-7">
          <h1 className="title-6 quicksand-bold-black-48px">{title}</h1>
          <a href="javascript:history.back()">
            <img className="ellipse-5-4" src={ellipse5} alt="Ellipse 5" />
          </a>
        </div>
        <div className="flex-row-12">
          <div className="kaleorid-1 quicksand-medium-black-20px">{kaleorid}</div>
          <img className="icon-trash" src={iconTrash1} alt="icon-trash" />
        </div>
        <div className="address-2 quicksand-medium-black-20px">{address1}</div>
        <div className="flex-row-13">
          <div className="aranesp quicksand-medium-black-20px">{aranesp}</div>
          <img className="icon-trash" src={iconTrash2} alt="icon-trash" />
        </div>
        <p className="address-3 quicksand-medium-black-20px">{address2}</p>
        <div className="flex-row-14">
          <div className="cacit quicksand-medium-black-20px">{cacit}</div>
          <img className="icon-trash" src={iconTrash3} alt="icon-trash" />
        </div>
        <div className="address-4 quicksand-medium-black-20px">{address3}</div>
        <div className="flex-row-15">
          <div className="mimpara quicksand-medium-black-20px">{mimpara}</div>
          <img className="icon-trash" src={iconTrash4} alt="icon-trash" />
        </div>
        <div className="address-2 quicksand-medium-black-20px">{address4}</div>
        <div className="overlap-group1-6">
          <div className="historique-1 quicksand-medium-black-16px">{historique}</div>
          <Link to="/histo-traitement">
            <img className="ellipse-11-2" src={ellipse11} alt="Ellipse 11" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ListeTraitement1;
