import React from "react";
import "./HistoTraitement.css";

function HistoTraitement(props) {
  const {
    title,
    ellipse5,
    historique,
    resikali,
    arrtLe15022023,
    neorecormon,
    arrtLe24012023,
    unAlfa,
    arrtLe12112022,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="histo-traitement quicksand-medium-black-20px screen">
        <div className="overlap-group-8">
          <div className="overlap-group1-7">
            <h1 className="title-7 quicksand-bold-black-48px">{title}</h1>
            <a href="javascript:history.back()">
              <img className="ellipse-5-5" src={ellipse5} alt="Ellipse 5" />
            </a>
          </div>
          <div className="historique-2">{historique}</div>
        </div>
        <div className="resikali">{resikali}</div>
        <div className="arrt-le-15022023">{arrtLe15022023}</div>
        <div className="neorecormon">{neorecormon}</div>
        <div className="arrt-le-24012023">{arrtLe24012023}</div>
        <div className="un-alfa">{unAlfa}</div>
        <div className="arrt-le-12112022">{arrtLe12112022}</div>
      </div>
    </div>
  );
}

export default HistoTraitement;
