import React from "react";
import { Link } from "react-router-dom";
import "./ChoixProfil3.css";

function ChoixProfil3(props) {
  const { place, jeSuisPatient, jeSuisMdecin } = props;

  return (
    <div className="container-center-horizontal">
      <div className="choixu95profil2 screen">
        <h1 className="place-2 quicksand-bold-black-48px">{place}</h1>
        <div className="overlap-group-11">
          <Link to="/accueil">
            <div className="rectangle-2"></div>
          </Link>
          <div className="je-suis-patient quicksand-medium-black-32px">{jeSuisPatient}</div>
        </div>
        <div className="overlap-group1-10">
          <Link to="/inter-medecin1">
            <div className="rectangle-3-2"></div>
          </Link>
          <div className="je-suis-mdecin quicksand-medium-black-32px">{jeSuisMdecin}</div>
        </div>
      </div>
    </div>
  );
}

export default ChoixProfil3;
