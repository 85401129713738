
import "./ProfilDoctor.css";
import { useHistory  } from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";


function ProfilUser(props) {
  const [userData, setUserData] = useState(null);
  const [doctorList, setDoctorList] = useState([]);
  const { ellipse3, title, franoisDupont, date, phone, fDupontGmailCom, modifier, changerMotDePasse } = props;
  const history = useHistory();


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('https://srv475095.hstgr.cloud/serein/doctor-info', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.data) {
          // Désérialiser les données JSON
          const parsedData = JSON.parse(response.data);
          setUserData(parsedData);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur:', error);
      }
    };

    fetchUserData();
  }, []);

  if (!userData) {
    return null; // Peut afficher un loader ou un message de chargement ici
  }


  

const fetchDoctorList = async () => {
  try {
    const response = await axios.get('https://srv475095.hstgr.cloud/serein/doctors', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    if (response.data && response.data.doctors) {
      setDoctorList(response.data.doctors);
    }
  } catch (error) {
    console.error('Erreur lors de la récupération de la liste des médecins:', error);
  }
};


    // Fonction pour se déconnecter
    const handleLogout = async () => {
      try {
        const response = await fetch('https://srv475095.hstgr.cloud/serein/logoutDoc', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`// Ajoutez le token ici si nécessaire
          },
        });
  
        if (response.ok) {
          localStorage.removeItem('token'); // Supprimez le token après la déconnexion réussie
          // Déconnexion réussie, redirigez l'utilisateur vers la page de connexion
          history.push('/LoginDoctor'); 
        } else {
          const errorData = await response.json();
          console.error('Erreur lors de la déconnexion:', errorData.detail);
        }
      } catch (error) {
        console.error('Erreur lors de la déconnexion:', error);
      }
    };


  return (
    <div className="container-center-horizontal">
      <div className="profil-user screen">
        <div className="flex-row-2">
          <a href="javascript:history.back()">
            <img className="ellipse-3" src={ellipse3} alt="Ellipse 3" />
          </a>
          <h1 className="title-1 quicksand-bold-black-48px">{title}</h1>
        </div>
        <div className="flex-row-3">
          <img className="icon-user" src="/img/profil-2-1-2.svg" alt="icon-user" />
          <div className="franois-dupont quicksand-medium-black-20px">{userData.first_name} {userData.last_name}</div>
        </div>
        <div className="flex-row-4">
          <img className="icon-calendar" src="/img/calendrier-1-2.svg" alt="icon-calendar" />
          <div className="date quicksand-medium-black-20px"> {userData.speciality}</div>
        </div>
        <div className="flex-row-5">
          <img className="tel-1" src="/img/tel-1-2.svg" alt="tel 1" />
          <p className="phone quicksand-medium-black-20px">{userData.phone_number}</p>
        </div>
        <div className="mail-container">
          <img className="icon-mail" src="/img/mail-1-2.svg" alt="icon-mail" />
          <a href="mailto:f.dupont@gmail.com" target="_blank">
            <div className="fdupontgmailcom quicksand-medium-black-20px">{userData.email}</div>
          </a>
        </div>
        <div className="flex-row-6">
          <img className="icon-home" src="/img/maison-1-2.svg" alt="icon-home" />
          <p className="address quicksand-medium-black-20px">{userData.address}</p>
        </div>
        <div className="overlap-group-3">
          <div className="modifier quicksand-medium-black-20px">{modifier}</div>
        </div>

        <div className="overlap-group-3">
          <div className="modifier quicksand-medium-black-20px">Edit MDP</div>
        </div>

        <div className="logout-button">
          <button onClick={handleLogout}>Déconnexion</button>
        </div>
       

      </div>
    </div>
    );
  
}

export default ProfilUser;


