import React, { useState  } from 'react';
import axios from 'axios'; 
import { Link, useHistory  } from "react-router-dom";
import './DoctorRegistrationPage.css';

function DoctorRegistrationPage ({}){
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [specialty, setSpeciality] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post('https://srv475095.hstgr.cloud/serein/register-doctor', {
        firstName,
        lastName,
        email,
        phoneNumber,
        address,
        specialty,
        password
      });

      console.log(response.data);

      if (response.status === 200) {
        history.push('/identification'); // Redirection vers la page de login
      }
    } catch (error) {
      console.error('Error:', error);

      if (error.response && error.response.data && error.response.data.detail) {
        console.log('Details de l\'erreur:', error.response.data.detail);
      }
    }
  };

  return (
    <div className="enregistrement-container">
        <form onSubmit={handleSubmit} className="enregistrement-form">

            <h1>Register</h1>

            <div className="form-group">
                <label>
                    Nom:
                <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                    required
                />
               </label>
            </div>

            <div className="form-group">
                <label>
                    Prenom:
                <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                required
                />
                </label>
            </div>

            <div className="form-group">
                <label htmlFor="email">
                    Adresse e-mail:
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                />
                </label>
            </div>

            <div className="form-group">
                <label htmlFor="tel">
                    Numéro de téléphone:
                <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Phone Number"
                    required
                />
                </label>
            </div>


            <div className="form-group">
                <label>
                    Adresse:
                <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                />
                </label>
            </div>

            <div className="form-group">
                <label>
                    Spécialité :
                <input
                    type="text"
                    value={specialty}
                    onChange={(e) => setSpeciality(e.target.value)}
                    placeholder="Spécialité"
                    required
                />
                </label>
            </div>

            <div className="form-group">
                <label>
                    Mot de Passe
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                </label>    
            </div>

            
        <p><Link to="/identification">Back</Link></p>
        <button type="submit">Enregistrer</button>
        </form>
    </div>
  );
}

export default DoctorRegistrationPage;
