import React, { useState, useEffect } from "react";
import Chart from "chart.js/auto";
import React from "react";
import "./Evolution.css";


function Evolution(props) {
  const {
    ellipse5,
    title,
    cratinine,
    graph11,
    potassium,
    graphe21,
    calcmie,
    graph31,
    hmoglobine,
    graph41,
  } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response1 = await fetch('https://srv475095.hstgr.cloud/serein/bilanbio');
      const response2 = await fetch('https://srv475095.hstgr.cloud/serein/graphe');
      const data1 = await response1.json();
      const data2 = await response2.json();
      setData({ bilanbioData: data1, grapheData: data2 });
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (data && data.grapheData) {
      const potassiumChart = new Chart("potassiumChart", {
        type: "line",
        data: {
          labels: data.grapheData.potassiumDates,
          datasets: [
            {
              label: "Hématies",
              data: data.grapheData.potassiumValues,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
      });

      const creatinineChart = new Chart("creatinineChart", {
        type: "line",
        data: {
          labels: data.grapheData.creatinineDates,
          datasets: [
            {
              label: "Leucocytes",
              data: data.grapheData.creatinineValues,
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1,
            },
          ],
        },
      });
    }
  }, [data]);

  return (
    <div className="container-center-horizontal">
      {data && (
        <div className="evolution screen">
          <div className="rectangle-7-1"></div>
          <a href="/accueil">
          <button><img className="ellipse-5" src={ellipse5} alt="Ellipse 5" /></button>
          </a>
          <h1 className="title-3 quicksand-bold-black-48px">{title}</h1>
          <div className="cratinine quicksand-medium-black-20px">
            {cratinine}
            <p>Dernière analyse: {data.bilanbioData.date_crea}</p>
            <p>Valeur: {data.bilanbioData.value_crea}</p>
          </div>
          <div>
            <canvas id="creatinineChart"></canvas>
          </div>
          <div className="potassium quicksand-medium-black-20px">
            {potassium}
            <p>Dernière analyse: {data.bilanbioData.date_pot}</p>
            <p>Valeur: {data.bilanbioData.value_pot}</p>
          </div>
          <div>
            <canvas id="potassiumChart"></canvas>
          </div>
          <div className="calcmie quicksand-medium-black-20px">{calcmie}</div>
          <img className="graph3-1" src={graph31} alt="Graph3 1" />
          <div className="hmoglobine quicksand-medium-black-20px">
            {hmoglobine}
          </div>
          <img className="graph4-1" src={graph41} alt="Graph4 1" />
        </div>
      )}
    </div>
  );
  
}

export default Evolution;
