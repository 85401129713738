import React from "react";
import "./ChoixProfil.css";

function ChoixProfil(props) {
  const { motDePasseOubli, ellipse11, adresseMail, envoyer } = props;

  return (
    <div className="container-center-horizontal">
      <div className="choix-profil-3 screen">
        <div className="overlap-group">
          <h1 className="mot-de-passe-oubli">{motDePasseOubli}</h1>
          <a href="javascript:history.back()">
            <img className="ellipse-11" src={ellipse11} alt="Ellipse 11" />
          </a>
        </div>
        <div className="adresse-mail quicksand-medium-black-20px">{adresseMail}</div>
        <div className="rectangle-3"></div>
        <div className="overlap-group1">
          <div className="envoyer quicksand-medium-black-20px">{envoyer}</div>
        </div>
      </div>
    </div>
  );
}

export default ChoixProfil;
