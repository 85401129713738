import "./App.css";
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import ChoixProfil from "./components/ChoixProfil";
import Accueil from "./components/Accueil";
import Camera from "./components/Camera";
import ChoixProfil2 from "./components/ChoixProfil2";
import ProfilUser from "./components/ProfilUser";
import Fichiers from "./components/Fichiers";
import Evolution from "./components/Evolution";
import Analyse1 from "./components/Analyse1";
import ListeTraitement2 from "./components/ListeTraitement2";
import Urgence from "./components/Urgence";
import ListeTraitement1 from "./components/ListeTraitement1";
import HistoTraitement from "./components/HistoTraitement";
import ComptePatient from "./components/ComptePatient";
import InterMedecin1 from "./components/InterMedecin1";
import ChoixProfil3 from "./components/ChoixProfil3";
import InfoPerso from "./components/InfoPerso";
import ModifierProfil from "./components/ModifierProfil";
import InterMedecin2 from "./components/InterMedecin2";
import Analyse2 from "./components/Analyse2";
import ChoixProfil4 from "./components/ChoixProfil4";
import IdentificationPage from './components/IdentificationPage';
import EnregistrementPatientComponent from './components/EnregistrementPatientComponent';
import DoctorRegistrationPage from './components/DoctorRegistrationPage';
import RegistrationForm from './components/RegistrationForm';
import LoginDoc from './components/LoginDoc';
import ProfilDoctor from './components/ProfilDoctor';

  /* The following line can be included in your src/index.js or App.js file */
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
  return (
    <Router>
      <Switch>
        <Route path="/choix-profil-3">
          <ChoixProfil
            motDePasseOubli={
              <React.Fragment>
                Mot de passe
                <br />
                oublié
              </React.Fragment>
            }
            ellipse11="/img/ellipse-5@2x.png"
            adresseMail="Adresse mail"
            envoyer="Envoyer"
          />
        </Route>

        <Route path="/accueil/:id">
          <Accueil {...accueilData} />
        </Route>

        <Route path="/ChoixProfil2">
          <Accueil {...accueilData} />
        </Route>

        <Route path="/identification">
          <IdentificationPage />
        </Route>


        <Route path="/DocRegis">
          <DoctorRegistrationPage />
        </Route>

        <Route path="/DoctorRegister">
          <RegistrationForm />
        </Route>

        <Route path="/LoginDoctor">
          <LoginDoc />
        </Route>

        <Route path="/Profil-Doctor">
          <ProfilDoctor  {...profilUserData}/>
        </Route>


        <Route path="/camera">
          <Camera src="/img/image-1.png" />
        </Route>
        <Route path="/choixu95profil">
          <ChoixProfil2
            title="Connexion"
            identifiant="Identifiant"
            motDePasse="Mot de passe"
            motDePasseOubli="Mot de passe oublié"
            seConnecter="Se connecter"
          />
        </Route>
        <Route path="/profil-user">
          <ProfilUser {...profilUserData} />
        </Route>
        <Route path="/fichiers/:id">
          <Fichiers />
        </Route>
        <Route path="/evolution">
          <Evolution {...evolutionData} />
        </Route>
        <Route path="/analyse1">
          <Analyse1 {...analyse1Data} />
        </Route>

        <Route path="/liste-traitement2">
          <ListeTraitement2 {...listeTraitement2Data} />
        </Route>
        <Route path="/urgence">
          <Urgence {...urgenceData} />
        </Route>
        <Route path="/liste-traitement1">
          <ListeTraitement1 {...listeTraitement1Data} />
        </Route>
        <Route path="/histo-traitement">
          <HistoTraitement {...histoTraitementData} />
        </Route>
        <Route path="/compte-patient/:id">
          <ComptePatient {...comptePatientData} />
        </Route>
        <Route path="/inter-medecin1/:id">
          <InterMedecin1 {...interMedecin1Data} />
        </Route>
        <Route path="/choixu95profil2">
          <ChoixProfil3 place="SeRein" jeSuisPatient="Je suis patient" jeSuisMdecin="Je suis médecin" />
        </Route>

        <Route path="/enregistrement-patient">
          <EnregistrementPatientComponent />
        </Route>


        <Route path="/info-perso/:id">
          <InfoPerso
            informationsPersonnelles={
              <React.Fragment>
                Informations
                <br />
                personnelles
              </React.Fragment>
            }
            ellipse3="/img/ellipse-5@2x.png"
            nomDuPatient="Nom du patient"
            dateDeNaissance="Date de naissance"
            nDeTlphone="N° de téléphone"
            adresseMail="Adresse mail"
            adresse="Adresse"
          />
        </Route>
        <Route path="/modifier-profil">
          <ModifierProfil {...modifierProfilData} />
        </Route>
        <Route path="/inter-medecin2">
          <InterMedecin2 {...interMedecin2Data} />
        </Route>
        <Route path="/analyse2">
          <Analyse2
            ellipse5="/img/ellipse-5@2x.png"
            title="Analyses"
            votrePossiumEstTr="Votre possium est trop bas. Avez-vous bien pris le médicament “KALEORID” prescrit par votre médecin ?"
            oui="OUI"
            votrePotassiumEst={
              <React.Fragment>
                Votre potassium est trop bas malgré <br />
                le traitement prescrit par votre <br />
                médecin. Contactez rapidement <br />
                votre médecin néphrologue pour <br />
                l’avertir des résultats et qu’il adapte
                <br />
                votre traitement.
              </React.Fragment>
            }
          />
        </Route>
        <Route path="/:path(|choix-profil)">
          <ChoixProfil4 place="SeRein" jeSuisPatient="Je suis patient" jeSuisMdecin="Je suis médecin" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;


const accueilData = {
    iconNotifications: "/img/ellipse-2@2x.png",
    place: "SeRein",
    ellipse1: "/img/ellipse-1@2x.png",
    appareilPhoto: "Appareil photo",
    analyses: "Analyses",
    traitements: "Traitements",
    fichiers: "Fichiers",
    evolutions: "Evolutions",
    numrosDurgence: <React.Fragment>Numéros<br />d’urgence</React.Fragment>,
};

const profilUserData = {
    ellipse3: "/img/ellipse-5@2x.png",
    title: "Profil",
    franoisDupont: "François DUPONT",
    date: "15/04/1973",
    phone: "06 78 43 59 61",
    fDupontGmailCom: "f.dupont@gmail.com",
    address: <React.Fragment>35 rue lavoir <br />77127 Lieusaint</React.Fragment>,
    modifier: "Modifier",
    changerMotDePasse: "Changer Mot de passe",
};

const fichiersData = {
    ellipse4: "/img/ellipse-5@2x.png",
    ordo11: "/img/ordo-1-1@2x.png",
    ordo31: "/img/ordo-3-1@2x.png",
    ordo12: "/img/ordo-1-1@2x.png",
    ordo32: "/img/ordo-3-1@2x.png",
    ordo21: "/img/ordo-2-1@2x.png",
    bilan21: "/img/bilan-2-1@2x.png",
    ordo22: "/img/ordo-2-1@2x.png",
    bilan22: "/img/bilan-2-1@2x.png",
    title: "Fichiers",
    bilan11: "/img/bilan-1-1@2x.png",
    bilan31: "/img/bilan-3-1@2x.png",
    bilan12: "/img/bilan-1-1@2x.png",
    bilan32: "/img/bilan-3-1@2x.png",
    ellipse6: "/img/ellipse-6@2x.png",
};

const evolutionData = {
    ellipse5: "/img/ellipse-5@2x.png",
    title: "Evolution",
    cratinine: "Leucocytes",
    graph11: "/img/graph1-1@2x.png",
    potassium: "Hématies",
    graph21: "/img/graph-2-1@2x.png",
    calcmie: "VGM",
    graph31: "/img/graph3-1@2x.png",
    hmoglobine: "Hémoglobine",
    graph41: "/img/graph4-1@2x.png",
};

const analyse1Data = {
    ellipse5: "/img/ellipse-5@2x.png",
    title: "Analyses",
    votrePossiumEstTr: "Votre possium est trop bas. Avez-vous bien pris le médicament “KALEORID” prescrit par votre médecin ?",
    oui1: "OUI",
    votrePotassiumEst: <React.Fragment>Votre potassium est trop bas malgré <br />le traitement prescrit par votre <br />médecin. Contactez rapidement <br />votre médecin néphrologue pour <br />l’avertir des résultats et qu’il adapte<br />votre traitement.</React.Fragment>,
    oui2: "OUI",
    oui3: "OUI",
    non: "NON",
};

const listeTraitement2Data = {
    title: "Traitements",
    ellipse5: "/img/ellipse-5@2x.png",
    kaleorid: "KALEORID",
    address1: "1 comprimé le matin",
    aranesp: "ARANESP",
    address2: "1 comprimé matin et soir",
    cacit: "CACIT",
    address3: "2 comprimés le midi",
    mimpara: "MIMPARA",
    address4: "2 comprimés le soir",
    historique: "Historique",
    ellipse11: "/img/ellipse-11@2x.png",
};

const urgenceData = {
    numrosDurgence: <React.Fragment>Numéros <br />d’urgence</React.Fragment>,
    ellipse5: "/img/ellipse-5@2x.png",
    number1: "185",
    samu: "SAMU",
    number2: "17",
    policeSecours: "Police Secours",
    number3: "18",
    pompiers: "Pompiers",
    number4: "112",
    numroDappelDurgenceEuropen: <React.Fragment>Numéro d’Appel <br />d’Urgence Européen</React.Fragment>,
};

const listeTraitement1Data = {
    title: "Traitements",
    ellipse5: "/img/ellipse-5@2x.png",
    kaleorid: "KALEORID",
    iconTrash1: "/img/ellipse-15@2x.png",
    address1: "1 comprimé le matin",
    aranesp: "ARANESP",
    iconTrash2: "/img/ellipse-15@2x.png",
    address2: "1 comprimé matin et soir",
    cacit: "CACIT",
    iconTrash3: "/img/ellipse-15@2x.png",
    address3: "2 comprimés le midi",
    mimpara: "MIMPARA",
    iconTrash4: "/img/ellipse-15@2x.png",
    address4: "2 comprimés le soir",
    historique: "Historique",
    ellipse11: "/img/ellipse-11@2x.png",
};

const histoTraitementData = {
    title: "Traitements",
    ellipse5: "/img/ellipse-5@2x.png",
    historique: "Historique",
    resikali: "RESIKALI",
    arrtLe15022023: "Arrêté le 15/02/2023",
    neorecormon: "NEORECORMON",
    arrtLe24012023: "Arrêté le 24/01/2023",
    unAlfa: "UN ALFA",
    arrtLe12112022: "Arrêté le 12/11/2022",
};

const comptePatientData = {
    ellipse11: "/img/ellipse-5@2x.png",
    nomDuPatient: <React.Fragment>Nom du <br />patient</React.Fragment>,
    infoso1: "/img/info-perso-1@2x.png",
    informationsPersonnelles: "Informations personnelles",
    fichiers: "Fichiers",
    analyses: "Analyses",
    traitements: "Traitements",
    evolutions: "Evolutions",
};

const interMedecin1Data = {
    ellipse1: "/img/ellipse-1@2x.png",
    place: "SeRein",
    rechercher: "Rechercher",
    uniquementMesPatients: "Uniquement mes patients",
    franoisDupont: "François DUPONT",
    iconNotifications1: "/img/cloche-1@2x.png",
    name1: "Paul MARTIN",
    iconNotifications2: "/img/cloche-1@2x.png",
    name2: "Emma DURANT",
    iconNotifications3: "/img/cloche-1@2x.png",
    valrieMoreau: "Valérie MOREAU",
    iconNotifications4: "/img/cloche-1@2x.png",
    philippeAndre: "Philippe ANDRE",
    iconNotifications5: "/img/cloche-1@2x.png",
    sylvieLeblanc: "Sylvie LEBLANC",
    iconNotifications6: "/img/cloche-1@2x.png",
    name3: "Pierre BERNARD",
    iconNotifications7: "/img/cloche-1@2x.png",
    franckDelorme: "Franck DELORME",
    iconNotifications8: "/img/cloche-1@2x.png",
    name4: "Jean DUPUY",
    iconNotifications9: "/img/cloche-1@2x.png",
    name5: "Sophie MOULIN",
    iconNotifications10: "/img/cloche-1@2x.png",
};

const modifierProfilData = {
    ellipse3: "/img/ellipse-5@2x.png",
    title: "Profil",
    franoisDupont: "François DUPONT",
    date: "15/04/1973",
    phone: "06 78 43 59 61",
    fDupontGmailCom: "f.dupont@gmail.com",
    address: <React.Fragment>35 rue du lavoir <br />77127 Lieusaint</React.Fragment>,
    enregistrer: "Enregistrer",
};

const interMedecin2Data = {
    ellipse1: "/img/ellipse-1@2x.png",
    place: "SeRein",
    rechercher: "Rechercher",
    uniquementMesPatients: "Uniquement mes patients",
    franoisDupont: "François DUPONT",
    iconNotifications1: "/img/cloche-1@2x.png",
    name1: "Paul MARTIN",
    iconNotifications2: "/img/cloche-1@2x.png",
    name2: "Emma DURANT",
    iconNotifications3: "/img/cloche-1@2x.png",
    valrieMoreau: "Valérie MOREAU",
    iconNotifications4: "/img/cloche-1@2x.png",
};

