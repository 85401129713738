import React, { useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom"; 
import axios from "axios";
import "./Rectangle1.css";


//import jsPDF from "jspdf";




function Rectangle1() {
  const history = useHistory();
  const { id } = useParams(); 
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  

  let mediaStream = null;


  
  const [selectedFile, setSelectedFile] = useState(null);
  const [response, setResponse] = useState("");
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false); // déplacer l'état du popup dans le composant principal
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log(selectedFile);
  };

 

  

  // return (
  //   <div>
  //     <button onClick={startCamera}>Activer la caméra</button>
  //     <video ref={videoRef} autoPlay playsInline />
  //   </div>
  // );

  


  const handleUploadButtonClick = async () => {
    if (!selectedFile) {
      setMessage("Please select a file.");
      return;
    }



    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append('patient_id', id); 

    console.log(formData.get("file"));

    console.log("formData:", formData);

    // const Modal = ({ capturedImage, onClose }) => {
    //   return (
    //     <div className="modal">
    //       <div className="modal-content">
    //         <img src={capturedImage} alt="Captured" />
    //         <button onClick={onClose}>Fermer</button>
    //       </div>
    //     </div>
    //   );
    // };
    
    // const config = {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    // };

    try {
      const response = await axios.post(
        "https://srv475095.hstgr.cloud/serein/upload",
        formData
      );
      console.log(response.data);
      setResponse(response.data.message);

       // Navigate to the analysis page after successful upload
       history.push("/analyse1");
    } catch (error) {
      console.log(error);
      setMessage("Failed to upload file. Please try again.");
    }
  };

  const handleClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

//   const takePhoto = async () => {
//     // Récupérer le canvas et son contexte 2D
//     const canvas = canvasRef.current;
//     const context = canvas.getContext('2d');

//     // Dessiner l'image du flux vidéo sur le canvas
//     context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

//     // Convertir le canvas en base64
//     const imageData = canvas.toDataURL('image/png');

//     setPhotoCapturée(true); // Indique que la photo a été capturée
//     setImageData(imageData); // Enregistre les données de l'image capturée dans l'état


//     // Envoyer l'image capturée au serveur
//     try {
//       // Envoyer l'image capturée au serveur
//       const response = await axios.post('https://srv475095.hstgr.cloud/serein/upload', { image: imageData });
//       console.log('Image enregistrée avec succès dans la base de données !', response.data);
//   } catch (error) {
//       console.error('Erreur lors de l\'enregistrement de l\'image :', error);
//   }
// };
    // <div>
    //   <input type="file" accept="image/*" onChange={handleFileChange} />
    //   <button onClick={handleImageUpload}>Upload Image</button>
    // </div>
  
  


 
  

  return (
    <div>
      <div className="rectangle-1">
        <button className="upload-btn" onClick={handleClick}>
          <img className="upload" src="/img/upload.png" alt="upload" />
        </button>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Téléchargez vos bilans de santé</h2>

            <div className="popup-inner">

                <h4>Ajoutez un fichier pdf</h4>
                <input type="file" onChange={handleFileInputChange} />
                <button onClick={handleUploadButtonClick}>Upload</button>
                {response && message && <p>{message}</p>}
           
              
            </div>
            <div>
            <button onClick={handlePopupClose}>Fermer</button>
            </div>
          </div>

        </div>
      )}
    </div>
  );
}

export default Rectangle1;


