import React, { useState, useEffect } from 'react';
import { useParams  } from "react-router-dom";
import './Fichiers.css'; // Importez le CSS que vous avez créé

function Fichiers() {
  const { id } = useParams();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetch(`https://srv475095.hstgr.cloud/serein/patient-files/${id}`)
      .then(response => response.json())
      .then(data => setFiles(data));
  }, []);

  const viewFile = (fileId) => {
    window.open(`https://srv475095.hstgr.cloud/serein/view/${fileId}`, '_blank');
  };

  const downloadFile = (fileId) => {
    window.open(`https://srv475095.hstgr.cloud/serein/download/${fileId}`);
  };

  return (
    <div>
      <h2>Liste des fichiers :</h2>
      <div className="file-container">
        {files.map((file) => (
          <div className="file-card" key={file._id}>
            <img src={file.thumbnail_url} alt={file.file_name} />
            <div>{file.file_name}</div>
            <button onClick={() => viewFile(file._id)}>Voir</button>
            <button onClick={() => downloadFile(file._id)}>Télécharger</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Fichiers;